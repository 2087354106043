.navBar {
  @include flexible(flex, space-evenly, center);
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 70px;
  background-color: $white;
  position: fixed;
  top: 0px;
  z-index: 1000;

  .LogoContainer {
    @include flexible(flex, center, center);
    margin-left: 0.5rem;
    cursor: pointer;
  }

  .nav-links {
    @include flexible(flex, flex-end, center);
    list-style: none;

    .NavLink {
      @include flexible(flex, center, center);
      @include font(20px, 20px, 700);
      text-decoration: none;
      padding: 5px 0 2px;
      margin: 0 30px;
      cursor: pointer;
      color: $darkGray;
      position: relative;

      &::before {
        @include position(none, none, -12px, 0);
        content: "";
        height: 2px;
        width: 0%;
        background: $darkGray;
        position: absolute;
        transition: 0.4s ease-in-out;
      }

      &:hover::before {
        width: 100%;
      }
    }

    .active {
      li {
        @include font(24px, 20px, bold);
        color: $violet;
      }
    }
  }

  .navSocial {
    display: block;

    .socialItem {
      @include flexible(flex, space-evenly, center);

      .facebookIcon {
        svg {
          border-radius: 40%;
          font-size: 32px;
          fill: $darkGray;
          margin: 0;

          &:hover {
            fill: $facebook;
          }
        }
      }

      .instagramIcon {
        svg {
          background-color: $darkGray;
          border-radius: 50%;
          font-size: 32px;
          fill: $white;
          margin: 0;
          width: 25px;
          height: 25px;
          padding: 5px;

          &:hover {
            background-color: $instagram;
            fill: $white;
          }
        }
      }
    }

    .navMail {
      text-decoration: none;
      color: $darkGray;
    }
  }

  .mobile-menu-icon {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .navBar {
    @include flexible(flex, space-evenly, center);
    padding-top: 20px;
    width: 100%;
    height: 70px;
    padding-left: 5px;
    background-color: $white;
    position: fixed;
    top: 0px;
    z-index: 1000;

    .LogoContainer {
      @include flexible(flex, center, center);
      margin: 0 auto;
      left: 20px;
      position: absolute;
    }

    .nav-links {
      display: none;
    }

    ul.nav-links-mobile {
      padding: 0;
    }

    .nav-links-mobile {
      @include flexible(block, center, center);
      @include position(68px, none, none, 0);
      position: absolute;
      display: grid;
      place-content: center;
      list-style: none;
      background-color: $brightGray;
      transition: all 0.5s ease-out;
      width: 100%;
      height: 90vh;
      z-index: 999;

      .NavLink,
      li {
        color: $darkGray;
        font-weight: bold;
        text-align: center;
        margin: 0;
        padding: 25px 0;
        width: 100%;
        transition: all 0.5s ease;
        text-decoration: none;
      }

      .active {
        li {
          color: $violet;
        }
      }
    }

    .navSocial {
      display: none;
    }

    .mobile-menu-icon {
      @include flexible(block, center, center);
      @include font(30px, 30px, bold);
      position: absolute;
      color: $darkGray;
      background-color: $white;
      border: none;
      outline: none;
      top: 17px;
      right: 20px;
      cursor: pointer;

      svg {
        padding-top: 20px;
        margin: 0;
      }
    }
  }
}