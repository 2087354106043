.container-slider {
  position: relative;
  margin: 0 auto;
  max-width: 98%;
  height: 500px;
  margin: 130px auto 100px auto;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;

  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
    margin: 0 auto;

    .btn-container {
      position: absolute;
      @include position(none, none, 15%, 6%);
    }

    .tittleSliderContainer {
      @include position(25%, none, none, 6%);
      position: absolute;
      max-width: 100%;
      color: $white;

      h2 {
        @include font(32px, 46px, bold);
        margin: 0;
      }
    }

    .subTittleSliderContainer {
      @include position(45%, none, none, 6%);
      position: absolute;
      max-width: 500px;
      color: $white;

      h3 {
        @include font(26px, 32px, 500);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .active-anim {
    opacity: 1;
  }

  .btn-slide {
    @include flexible(flex, center, center);
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: $white;
    border: 1px solid rgba(34, 34, 34, 0.287);
    cursor: pointer;

    img {
      width: 25px;
      height: 25px;
      pointer-events: none;
    }
  }

  .prev {
    @include position(50%, none, none, 20px);
    transform: translateY(-60%);
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }

  .next {
    @include position(50%, 20px, none, none);
    transform: translateY(-60%);
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }

  .container-dots {
    @include flexible(flex, center, center);
    @include position(none, none, 10px, 50%);
    position: absolute;
    transform: translateX(-50%);

    .dot {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 3px solid $white;
      margin: 0 5px;
      background: $button;
      cursor: pointer;
    }

    .dot.active {
      background: $darkGray;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 880px) {
  .container-slider {
    margin: 150px 10px 100px 15px;

    .btn-slide {
      width: 40px;
      height: 40px;
    }

    .container-dots {
      .dot {
        width: 10px;
        height: 10px;
      }
    }

    .slide {
      text-align: center;

      .btn-container {
        @include position(75%, 0, none, 0);
        margin: auto;
      }

      .tittleSliderContainer {
        @include position(25%, 0, none, 0);

        h2 {
          @include font(24px, 46px, bold);
        }
        margin: auto;
      }

      .subTittleSliderContainer {
        @include position(55%, 0, none, 0);
        margin: auto;

        h3 {
          width: 80%;
          margin: auto;
          @include font(18px, 22px, 700);
        }
      }
    }
  }
}  