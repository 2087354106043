.btn-wsp {
  display: grid;
  place-content: center;
  position: fixed;
  width: 45px;
  height: 45px;
  line-height: 60px;
  bottom: 30px;
  right: 30px;
  background: $whatsapp;
  color: $white;
  border-radius: 50%;
  text-align: center;
  box-shadow:  0px 1px 10px rgb(0 0 0 / 30%);
  transition: 0.2s ease-in;
  z-index: 100;

  svg {
    font-size: 40px;
  }

  &:hover {
    color: $whatsapp;
    background: $white;
  }
}