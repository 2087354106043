.aboutContainer { 
  padding-top: 150px;
 
  .historyContainer {
    @include flexible(flex, space-around, center);
    gap: 8%;
    padding-bottom: 75px;
    margin: 0 3%;
    text-align: justify;
  }
 
  .historyContent {
    height: 100%;
 
    .historyTitle {
      @include font(32px, 46px, bold);
    }

    .historyText {
      @include font(26px, 32px, 500);
    }

    @media screen and (max-width: 1450px) {
      .historyTitle {
        @include font(26px, 46px, bold);
      }

      .historyText {
        @include font(20px, 32px, 500);
      }
    }

    @media screen and (max-width: 1200px) {
      .historyTitle {
        @include font(20px, 46px, bold);
      }

      .historyText {
        @include font(16px, 32px, 500);
      }
    }
  }

  .historyImageContent {
    margin: 0 auto;
    width: 100%;
    max-width: 468px;
    text-align: center;

    img {
      width: 448px;
    }
  }

  @media screen and (max-width: 1000px) {
    .historyContainer {
      display: grid;
      place-content: center;
      padding-bottom: 20px;
      gap: 0;

      .historyContent {
        text-align: center;
      }

      .historyImageContent {
        margin: 0 auto;

        img {
          width: 100%;
        }
      }
    }
  }

  .missionAndVisionContainer {
    background: $brightGray;
    padding-top: 75px;

    .missionAndVisionContent {
      @include flexible(flex, space-between, center);
      gap: 8%;
      padding-bottom: 75px;
      margin: 0 3%;
      text-align: justify;

      .missionContent {
        height: 100%;

        .missionTitle {
          @include font(32px, 46px, bold);
        }

        .missionSubTitle {
          @include font(26px, 38px, 700);
        }

        .missionText {
          @include font(26px, 32px, 500);
        }

        @media screen and (max-width: 1450px) {
          .missionTitle {
            @include font(26px, 46px, bold);
          }

          .missionSubTitle {
            @include font(20px, 38px, 700);
          }

          .missionText {
            @include font(20px, 32px, 500);
          }
        }

        @media screen and (max-width: 1200px) {
          .missionTitle {
            @include font(22px, 46px, bold);
          }

          .missionSubTitle {
            @include font(16px, 38px, 700);
          }

          .missionText {
            @include font(16px, 32px, 500);
          }
        }
      }

      .visionImageContent {
        margin: 0 auto;
        width: 100%;
        max-width: 468px;
        text-align: center;

        img {
          width: 448px;
        }
      }
    }

    @media screen and (max-width: 1000px) {
      .missionAndVisionContent {
        display: grid;
        place-content: center;
        gap: 0;
        padding-bottom: 20px;

        .missionContent {
          text-align: center;
        }

        .visionImageContent {
          margin: 0 auto;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .productiveProcessContainer {
    @include flexible(flex, space-between, center);
    padding-top: 100px;
    gap: 8%;
    padding-bottom: 75px;
    margin: 0 3%;
    text-align: justify;

    .productiveProcessContent {
      height: 100%;

      .productiveProcessTitle {
        @include font(32px, 46px, bold);
      }

      .productiveProcessText {
        @include font(26px, 32px, 500);
      }

      @media screen and (max-width: 1450px) {
        .productiveProcessTitle {
          @include font(26px, 46px, bold);
        }

        .productiveProcessText {
          @include font(20px, 32px, 500);
        }

        @media screen and (max-width: 1200px) {
          .productiveProcessTitle {
            @include font(22px, 46px, bold);
          }

          .productiveProcessText {
            @include font(16px, 32px, 500);
          }
        }
      }
    }

    .productiveProcessImageContent {
      margin: 0 auto;
      width: 100%;
      max-width: 468px;
      text-align: center;

      img {
        width: 448px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .productiveProcessContainer {
      display: grid;
      place-content: center;
      gap: 0;
      padding-bottom: 20px;

      .productiveProcessContent {
        text-align: center;
      }

      .productiveProcessImageContent {
        margin: 0 auto;

        img {
          width: 100%;
        }
      }
    }
  }
}