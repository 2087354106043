.successContainer {
    display: grid;
    place-content: center;
    text-align: center;
    width: 100%;
    height: 60vh;
    padding: 170px 0;
    
  .success{
    width: 100%;
      
    .successContent{
      padding-bottom: 50px;

      h1{
        @include font( 36px, 46px, bold);
      }
      
      h3 {
        @include font( 20px, 22px, 500);
        padding-top: 50px;
      }
    
      img {
        width: 100%;
        padding-top: 50px;
        margin: 0 5%;
        max-width: 350px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .success{
      .successContent{
        padding-bottom: 50px;
  
        h1{
          @include font(24px, 46px, bold);
        }
        
        h3 {
          @include font(16px, 16px, 500);
          padding-top: 50px;
          margin: 0 5%;
        }
      
        img {
          width: 100%;
          max-width: 280px;
          padding-top: 50px;
          margin: 0 5%;
        }
      }
    }  
  }      
} 