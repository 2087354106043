.footerContainer { 
  @include flexible(flex, space-evenly, center);
  @include font(14px, 18px, 500);
  text-align: center; 
  background-color: $violet; 
  color: $white; 
  padding: 30px 10px; 
 
  .footerContainer-location
  .footerContainer-contact
  a{
      text-decoration: none; 
      color: $white; 
    }   
 
  .footerContariner-copyright { 
    a { 
      text-decoration: none; 
      color: $white; 
      font-weight: bolder; 
    } 
  } 
 
  @media screen and (max-width: 1100px) {
    @include flexible(block, space-evenly, center);
    @include font(10px, 18px, 500);
    padding: 30px 10px 40px 10px; 
  } 
}