//Color Palette

$white      : #ffffff;
$black      : #000000;
$whatsapp   : #00BB2D;
$facebook   : #3b5998;
$instagram  : #e95950;
$button     : #089547;
$buttonHover: #35D475;
$darkGray   : #404e4e;
$brightGray : #EBEBEB;
$lightGreen : #089547b4;
$violet     : #787da7;
$error      : #dc3545; 